import {
  SET_GLOBALFILTER_IDS,
  SET_GLOBALFILTER_SAPCODE,
  SET_GLOBALRESETFILTER,
} from "./globalFilterTypes";

export const setGlobalFiltersIds = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_GLOBALFILTER_IDS,
      payload: data,
    });
  } catch (err) {}
};

export const setGlobalFiltersSapCode = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_GLOBALFILTER_SAPCODE,
      payload: data,
    });
  } catch (err) {}
};

export const setGlobalResetFilters = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_GLOBALRESETFILTER,
      payload: undefined,
    });
  } catch (err) {}
};
