import React, { Fragment, useState, useEffect } from "react";
import { Button, Card, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { pdfImg } from "../Elite/imageAssets";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import SecurityReportTable from "./Tables/securityReportTable";

const SecurityReport = () => {
  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const [isEmployee, setIsEmployee] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [applyFilterObj, setApplyFilterObj] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [SecurityReportTableData, setSecurityReportTableData] = useState({});

  useEffect(() => {
    fetchReportData();
    setIsEmployee(userDetails.isEmployee);
  }, []);

  useEffect(() => {
    if (Object.keys(applyFilterObj).length > 0) {
      fetchReportData();
    }
  }, [applyFilterObj]);
  //// handle Date Onchange code
  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  //// handle table column sorting and pagination
  const handleTableSortColumn = (pagination, filters, sorter) => {
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    const sortField = sorter.field || "";

    // Pass the selected date or default to today
    fetchReportData(
      selectedDate || moment().format("YYYY-MM-DD"),
      sortField,
      sortOrder,
      filters,
      pagination
    );
  };
  //// API calling function start
  //// fetch the report data api
  const fetchReportData = (
    date = moment().format("YYYY-MM-DD"),
    sortField = "",
    sortOrder = ""
  ) => {
    setIsTableLoading(true);

    const inputData = {
      pageindex: 1,
      pagesize: 10,
      sortcolumn: sortField,
      orderBy: sortOrder,
      search: date,
      isReport: true,
    };

    visitorManagementService
      .getCreateGatePassTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSecurityReportTableData(response.data.responseData || {});
        } else {
          NotificationManager.error(
            response.data.message || "Error fetching data"
          );
        }
      })
      .catch(() => NotificationManager.error("Error fetching data"))
      .finally(() => setIsTableLoading(false));
  };
  //Export the data API
  const handleExportData = () => {
    setIsTableLoading(true);
    const currentDate = selectedDate || moment().format("YYYY-MM-DD");
    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: "",
      orderBy: "",
      search: currentDate,
      isReport: true,
    };

    visitorManagementService
      .postReceptionReportExportPDF(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileLink = document.createElement("a");
        fileLink.href = window.URL.createObjectURL(blob);
        fileLink.setAttribute(
          "download",
          `Reception_Report_${currentDate}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        NotificationManager.success("Excel Export Successful!");
      })
      .catch(() => NotificationManager.error("Excel export failed."))
      .finally(() => setIsTableLoading(false));
  };
  //// API calling function end
  const handleSubmit = () => {
    if (!selectedDate) {
      NotificationManager.error("Please select a date to filter!");
      return;
    }
    fetchReportData(selectedDate);
  };
  //// handle Reset format code
  const handleResetFormData = () => {
    setSelectedDate(null);
    fetchReportData();
  };
  return (
    <Fragment>
      <div className="app-content content visitorPass securityReport">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Security Report
            </h5>
            <div className="gate_pass_section">
              <div className="visitor_reports row">
                <div className="report_section  col-xl-9 col-12">
                  <label>Date of visit</label>
                  <DatePicker
                    className="resport_date"
                    onChange={handleDateChange}
                    placeholder="Select Date"
                    showToday={false}
                    showSecond={false}
                    value={selectedDate ? moment(selectedDate) : null}
                  />

                  <div className="reset_save_btn pt-0">
                    <Button className="save_btn" onClick={handleSubmit}>
                      Submit
                    </Button>
                    <Button
                      className="reset_btn ml-20"
                      onClick={handleResetFormData}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                <div className="report_pdf col-xl-3 col-12 justify-content-end d-flex">
                  <label onClick={handleExportData}>
                    Export PDF
                    <img src={pdfImg} alt="img" />
                  </label>
                </div>
              </div>
            </div>

            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <SecurityReportTable
                    securityReportData={SecurityReportTableData}
                    gatePassLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    isEmployee={isEmployee}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SecurityReport;
