import React, { Fragment, useState, useEffect } from "react";
import EliteCardService from "../../services/elite-cards-service";
import { NotificationManager } from "react-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import formatNumber from "../../utils/formatNumber";
import { setTableSortingData } from "../../actions/tableListing/tableSortingActionData";

const EliteDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state || 0;

  const userApps = useSelector(
    (state) => state.loginReducer.loginDetials.userApplications
  );

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );
  const [totalOrder, setTotalOrder] = useState(0);
  const [isCardLoader, setIsCardLoader] = useState(false);
  // const [isPaymentCardLoader, setIsPaymentCardLoader] = useState(false);
  const [totalMaterialItems, setTotalMaterialItems] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalQuantityMT, setTotalQuantityMT] = useState(0);
  // const [isO2WHover, setIsO2WHover] = useState(false);
  // const [isLogisticWHover, setIsLogisticWHover] = useState(false);
  // const [isPaymentHover, setIsPaymentHover] = useState(false);
  const [paymentTileCounts, setPaymentTileCounts] = useState({});
  const [logisticTileCounts, setLogisticTileCounts] = useState({});
  const [userAppFuature, setUserAppFuature] = useState([]);

  useEffect(() => {
    handleGetO2WDashboardCount();
    handleGetPaymentDashboardCount();
    handleGetLogisticDashboardCount();
    if (userApps?.length > 0) {
      let tempFinalFeature = [];
      let finalResults = [];
      let tempFindFeatures = userApps.filter((x) => x.aP_Id === id);
      if (tempFindFeatures.length > 0) {
        tempFinalFeature = tempFindFeatures[0].features;
        const desiredOrder = [10, 11, 12, 3, 4, 9, 13]; //// features Ids
        tempFinalFeature?.forEach((el) => {
          el.isHover = false;
        });
        finalResults = tempFinalFeature.sort((a, b) => {
          return desiredOrder.indexOf(a.fT_Id) - desiredOrder.indexOf(b.fT_Id);
        });
      }
      setUserAppFuature(finalResults);
    }
  }, [companyDetails]);

  //// API calling function code start

  //// get the count of O2W Tile API
  const handleGetO2WDashboardCount = () => {
    setIsCardLoader(true);
    let inputData = {
      regional_Manager: "",
      general_Manager: "",
      area_Manager: "",
      company_Code: companyDetails ? companyDetails.company_Code : "",
    };
    EliteCardService.getO2wTileDashboardCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsCardLoader(false);
          setTotalOrder(objData.order_section.total);
          setTotalMaterialItems(objData.material_section.total);
          setTotalQuantity(objData.quantity_section.total);
          setTotalQuantityMT(objData.quantity_in_MT.total);
        } else {
          setIsCardLoader(false);
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      })
      .finally(() => {
        setIsCardLoader(false);
      });
  };
  /// get the count of Payment Tile APi
  const handleGetPaymentDashboardCount = () => {
    // setIsPaymentCardLoader(true);
    let inputData = {
      company_Code: companyDetails ? companyDetails.company_Code : "",
      type: "",
    };
    EliteCardService.getPaymentTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          // setIsPaymentCardLoader(false);
          setPaymentTileCounts(objData);
        } else {
          // setIsPaymentCardLoader(false);
          setPaymentTileCounts({});
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        // setIsPaymentCardLoader(false);
        console.log(error, "Error");
      });
  };
  //// get the count of Logistics Tile API
  const handleGetLogisticDashboardCount = () => {
    setIsCardLoader(true);
    let inputData = {
      regional_Manager: "",
      general_Manager: "",
      area_Manager: "",
      company_Code: companyDetails ? companyDetails.company_Code : "",
    };
    EliteCardService.getLogisticTileCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setIsCardLoader(false);
          setLogisticTileCounts(objData);
        } else {
          setIsCardLoader(false);
          setLogisticTileCounts({});
          NotificationManager.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      })
      .finally(() => {
        setIsCardLoader(false);
      });
  };
  //// API calling function code end

  //// handle card click function
  const handleCardClick = (pageId) => {
    if (pageId === 10) {
      navigate("/dashboard/all");
      dispatch(
        setTableSortingData({
          sortColumn: "customer_PO_Date",
          orderBy: "desc",
          currentPage: 1,
        })
      );
    } else if (pageId === 11) {
      navigate("/payment/customer");
    } else if (pageId === 12) {
      navigate("/logistic/all");
      // dispatch(
      //   setTableSortingData({
      //     currentPage: 1,
      //     orderBy: "desc",
      //     sortColumn: "",
      //   })
      // );
    }
  };
  //// handle card hover base on the id
  const handleHoverCard = (id) => {
    setUserAppFuature((prevApplications) =>
      prevApplications.map((item) =>
        item.fT_Id === id
          ? { ...item, isHover: true }
          : { ...item, isHover: false }
      )
    );
  };
  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              {userAppFuature?.length > 0 &&
                userAppFuature?.map((item) => {
                  return (
                    <div className="col-xl-3 col-lg-6 col-12" key={item.fT_Id}>
                      <div
                        className={`card card-hover cur_point ${
                          item.isHover ? "hovered" : ""
                        }`}
                        onClick={() => handleCardClick(item.fT_Id)}
                        onMouseEnter={() => handleHoverCard(item.fT_Id)}
                        onMouseLeave={() => handleHoverCard(item.fT_Id)}
                      >
                        <Spin
                          wrapperClassName="loader"
                          size="large"
                          spinning={isCardLoader}
                        >
                          <div className="card-content">
                            <div className="card-body dash_cards">
                              <h4 className="text-info cards o2w_card">
                                {item.fT_Name}
                              </h4>
                              {item.fT_Id === 10 ? (
                                <div>
                                  <div className="card_lbl">
                                    <label className="Lbold">Order</label>
                                    <label>{totalOrder}</label>
                                  </div>
                                  <div className="card_lbl">
                                    <label className="Lbold">
                                      Material Line Item
                                    </label>
                                    <label>{totalMaterialItems}</label>
                                  </div>
                                  <div className="card_lbl">
                                    <label className="Lbold">Quantity</label>
                                    <label>{totalQuantity}</label>
                                  </div>
                                  {userDetails.isEmployee && (
                                    <div className="card_lbl">
                                      <label className="Lbold">
                                        Quantity in MT
                                      </label>
                                      <label>{totalQuantityMT}</label>
                                    </div>
                                  )}
                                </div>
                              ) : item.fT_Id === 11 ? (
                                <div>
                                  <div className="card_lbl">
                                    <label className="Lbold">
                                      Pending Invoice Amount
                                    </label>
                                    <label>
                                      {Object.keys(paymentTileCounts).length > 0
                                        ? paymentTileCounts.totalPendingAmount_LCY
                                        : 0}
                                    </label>
                                  </div>
                                  <div className="card_lbl">
                                    <label className="Lbold">
                                      Pending Amount
                                    </label>
                                    <label style={{ whiteSpace: "nowrap" }}>
                                      {Object.keys(paymentTileCounts).length > 0
                                        ? formatNumber(
                                            paymentTileCounts.customerCount
                                          )
                                        : 0}
                                    </label>
                                  </div>
                                  <div className="card_lbl">
                                    <label className="Lbold">
                                      Overdue Amount
                                    </label>
                                    <label>
                                      {Object.keys(paymentTileCounts).length > 0
                                        ? formatNumber(
                                            paymentTileCounts.overDueCount
                                          )
                                        : 0}
                                    </label>
                                  </div>
                                </div>
                              ) : item.fT_Id === 12 ? (
                                <div>
                                  <div className="card_lbl">
                                    <label className="Lbold">
                                      Pending Dispatch
                                    </label>
                                    <label>
                                      {Object.keys(logisticTileCounts).length >
                                      0
                                        ? formatNumber(
                                            logisticTileCounts.pendingDispatchQtyCount
                                          )
                                        : 0}
                                    </label>
                                  </div>
                                  <div className="card_lbl">
                                    <label className="Lbold">In Transit</label>
                                    <label>
                                      {Object.keys(logisticTileCounts).length >
                                      0
                                        ? formatNumber(
                                            logisticTileCounts.transitOrdersCount
                                          )
                                        : 0}
                                    </label>
                                  </div>
                                  {userDetails.isEmployee && (
                                    <div className="card_lbl">
                                      <label className="Lbold">Delivered</label>
                                      <label>
                                        {Object.keys(logisticTileCounts)
                                          .length > 0
                                          ? formatNumber(
                                              logisticTileCounts.deliveredQtyCount
                                            )
                                          : 0}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Spin>
                      </div>
                    </div>
                  );
                })}
              {/* <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isO2WHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("O2W")}
                  onMouseEnter={() => setIsO2WHover(true)}
                  onMouseLeave={() => setIsO2WHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards o2w_card">
                          O2W (Order to Warehouse)
                        </h4>
                        <div className="card_lbl">
                          <label className="Lbold">Order</label>
                          <label>{totalOrder}</label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Material Line Item</label>
                          <label>{totalMaterialItems}</label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Quantity</label>
                          <label>{totalQuantity}</label>
                        </div>
                        {userDetails.isEmployee && (
                          <div className="card_lbl">
                            <label className="Lbold">Quantity in MT</label>
                            <label>{totalQuantityMT}</label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isPaymentHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("payment")}
                  onMouseEnter={() => setIsPaymentHover(true)}
                  onMouseLeave={() => setIsPaymentHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isPaymentCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards">Payment</h4>
                        <div className="card_lbl">
                          <label className="Lbold">
                            Pending Invoice Amount
                          </label>
                          <label>
                            {Object.keys(paymentTileCounts).length > 0
                              ? paymentTileCounts.totalPendingAmount_LCY
                              : 0}
                          </label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Pending Amount</label>
                          <label style={{ whiteSpace: "nowrap" }}>
                            {Object.keys(paymentTileCounts).length > 0
                              ? formatNumber(paymentTileCounts.customerCount)
                              : 0}
                          </label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">Overdue Amount</label>
                          <label>
                            {Object.keys(paymentTileCounts).length > 0
                              ? formatNumber(paymentTileCounts.overDueCount)
                              : 0}
                          </label>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isLogisticWHover ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("logistic")}
                  onMouseEnter={() => setIsLogisticWHover(true)}
                  onMouseLeave={() => setIsLogisticWHover(false)}
                >
                  <Spin
                    wrapperClassName="loader"
                    size="large"
                    spinning={isCardLoader}
                  >
                    <div className="card-content">
                      <div className="card-body dash_cards">
                        <h4 className="text-info cards o2w_card">Logistics</h4>
                        <div className="card_lbl">
                          <label className="Lbold">Pending Dispatch</label>
                          <label>
                            {Object.keys(logisticTileCounts).length > 0
                              ? formatNumber(
                                  logisticTileCounts.pendingDispatchQtyCount
                                )
                              : 0}
                          </label>
                        </div>
                        <div className="card_lbl">
                          <label className="Lbold">In Transit</label>
                          <label>
                            {Object.keys(logisticTileCounts).length > 0
                              ? formatNumber(
                                  logisticTileCounts.transitOrdersCount
                                )
                              : 0}
                          </label>
                        </div>
                        {userDetails.isEmployee && (
                          <div className="card_lbl">
                            <label className="Lbold">Delivered</label>
                            <label>
                              {Object.keys(logisticTileCounts).length > 0
                                ? formatNumber(
                                    logisticTileCounts.deliveredQtyCount
                                  )
                                : 0}
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Spin>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EliteDashboard;
