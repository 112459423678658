import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GalaxyLogo from "../../assets/images/logo-light-sm.png";
import imgCubes from "../../assets/images/cubes_menu.png";
import { useDispatch } from "react-redux";
import { Menu } from "antd";
import "./layout.css";
import {
  setTableSearchInput,
  setTableSortingData,
} from "../../actions/tableListing/tableSortingActionData";
import { useSelector } from "react-redux";
import {
  setGlobalFiltersIds,
  setGlobalFiltersSapCode,
} from "../../actions/globalFilter/globalFilterAction";

const { SubMenu } = Menu;

const Sidemenu = ({ collapsed, toggleMobileMenuCollapsed }) => {
  const dispatch = useDispatch();
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const userApps = useSelector(
    (state) => state.loginReducer.loginDetials.userApplications
  );

  //// resize code in useEffect
  useEffect(() => {
    const handleResize = () => {
      setIsMobileDevice(window.innerWidth <= 1025);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //// handle menu click code
  const handleMenuClick = () => {
    if (isMobileDevice) {
      toggleMobileMenuCollapsed();
    }
  };
  //// handle table column sorting and pagination code
  const handleTableSortingData = () => {
    dispatch(setTableSearchInput(""));
    dispatch(setGlobalFiltersIds(null));
    dispatch(setGlobalFiltersSapCode(null));
    dispatch(
      setTableSortingData({
        sortColumn: "customer_PO_Date", /// default sort column
        orderBy: "desc",
        currentPage: 1,
      })
    );
  };
  /// handle Set nul search data
  const handleSetSearchNullData = () => {
    dispatch(setTableSearchInput(""));
    dispatch(setGlobalFiltersIds(null));
    dispatch(setGlobalFiltersSapCode(null));
  };

  return (
    <nav className="sidenav__section">
      <button
        className="sidebar__close"
        onClick={() => toggleMobileMenuCollapsed()}
      >
        x
      </button>
      <Link to="/galaxyHome" className="logo_section" onClick={handleMenuClick}>
        <img src={GalaxyLogo} className="w-30" alt="Galaxy Logo" />
        <span className="px-1 logoTitle">Galaxy Portal</span>
      </Link>
      <div className="menu-content mt-3">
        <Menu
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          className="menu-items side_menu"
          onClick={handleMenuClick}
        >
          {userApps?.length > 0 &&
            userApps?.map((menu) => (
              <SubMenu
                key={menu.aP_Id}
                icon={
                  <img
                    src={imgCubes}
                    alt={`${menu.aP_Name} Icon`}
                    className="menu-icon"
                  />
                }
                title={<span>{menu.aP_Name}</span>}
              >
                {menu.features.map((feature) => (
                  <Menu.Item key={feature.fT_Id}>
                    <Link
                      to={`${
                        feature.fT_Name.toLowerCase().includes("o2w")
                          ? "/dashboard/all"
                          : feature.fT_Name.toLowerCase().includes("payment")
                          ? "/payment/customer"
                          : feature.fT_Name.toLowerCase().includes("logistics")
                          ? "/logistic/all"
                          : feature.fT_Name.toLowerCase().includes("visitor")
                          ? "/visitorHome"
                          : feature.fT_Name.toLowerCase().includes("factory")
                          ? "/factory/ReceptionReport"
                          : feature.fT_Name.toLowerCase().includes("office")
                          ? "/office/VisitorReport"
                          : feature.fT_Name.toLowerCase().replace(/ /g, "-")
                      }`}
                      onClick={
                        feature.fT_Name.includes("Order")
                          ? handleTableSortingData
                          : handleSetSearchNullData
                      }
                    >
                      {feature.fT_Name.includes("O2W")
                        ? "Order to Warehouse"
                        : feature.fT_Name}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
        </Menu>
      </div>
    </nav>
  );
};

export default Sidemenu;
