import React, { Fragment, useState, useEffect } from "react";
import { officeImg, factoryImg, reportImg } from "../Elite/imageAssets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const initialVisitorCardData = [
  {
    name: "Visit to Office",
    imageUrl: officeImg,
    pageUrl: "/office/CreateNewVisitor",
    isHoverCard: false,
    isVisible: false,
  },
  {
    name: "Office Visit Report",
    imageUrl: reportImg,
    pageUrl: "/office/VisitorReport",
    isHoverCard: false,
    isVisible: false,
  },
  {
    name: "Visit to Factory",
    imageUrl: factoryImg,
    pageUrl: "/factory/CreateGatePass",
    isHoverCard: false,
    isVisible: false,
  },
  {
    name: "Factory Visit Report",
    imageUrl: reportImg,
    pageUrl: "/factory/ReceptionReport",
    isHoverCard: false,
    isVisible: false,
  },
];

const VisitorCard = () => {
  const navigate = useNavigate();
  const userApps = useSelector(
    (state) => state.loginReducer.loginDetials.userApplications
  );

  const [visitorCardData, setVisitorCardData] = useState(
    initialVisitorCardData
  );

  useEffect(() => {
    if (userApps.length) {
      const tempVisitorData =
        userApps.find((x) => x.aP_Id === 9)?.features || [];

      const updatedVisitorCardData = initialVisitorCardData.map((card) => {
        const isVisible =
          (tempVisitorData.some((feature) =>
            feature.fT_Name.toLowerCase().includes("factory report")
          ) &&
            card.name.toLowerCase().includes("factory visit report")) ||
          (tempVisitorData.some((feature) =>
            feature.fT_Name.toLowerCase().includes("office report")
          ) &&
            card.name.toLowerCase().includes("office visit report")) ||
          (tempVisitorData.some((feature) =>
            feature.fT_Name.toLowerCase().includes("visitor & gatepass")
          ) &&
            (card.name.toLowerCase().includes("visit to office") ||
              card.name.toLowerCase().includes("visit to factory")));

        return { ...card, isVisible };
      });

      setVisitorCardData(updatedVisitorCardData);
    }
  }, [userApps]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  //// handle MouseHover effect code
  const handleMouseEnter = (index) => {
    const updatedData = visitorCardData.map((item, i) =>
      i === index ? { ...item, isHoverCard: true } : item
    );
    setVisitorCardData(updatedData);
  };

  ///// mouse leave effect code
  const handleMouseLeave = (index) => {
    const updatedData = visitorCardData.map((item, i) =>
      i === index ? { ...item, isHoverCard: false } : item
    );
    setVisitorCardData(updatedData);
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              {visitorCardData.map((item, i) => {
                return (
                  <>
                    {item.isVisible && (
                      <div className="col-xl-3 col-lg-6 col-12" key={i}>
                        <div
                          className={`card card-hover cur_point ${
                            item.isHoverCard ? "hovered" : ""
                          }`}
                          onClick={() => navigate(item.pageUrl)}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={() => handleMouseLeave(i)}
                        >
                          <div className="card-content">
                            <div className="card-body d-flex justify-content-between card_text">
                              <img src={item.imageUrl} alt="img" />
                              <h4 className="text-info pt-15">{item.name}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VisitorCard;
