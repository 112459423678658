export default function getCompanySortName(input) {
  if (!input) {
    return ""; // or return a default value if necessary
  }

  return input
    .split(" ")
    .map(function(word) {
      return word[0];
    })
    .join("");
}
