import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  SmallOrder,
  SmallMaterial,
  SmallQuantity,
  SmallMTQ,
} from "../imageAssets";
import { Card, Input, Tabs, Button, Spin, Switch } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import AllTab from "../../../components/DashboardTabs/AllTab";
import POTab from "../../../components/DashboardTabs/POTab";
import MaterialTab from "../../../components/DashboardTabs/MaterialTab";
import InvoiceTab from "../../../components/DashboardTabs/InvoiceTab";
import ContainerTab from "../../../components/DashboardTabs/ContainerTab";
import AssumptionEtaTab from "../../../components/DashboardTabs/AssumptionEtaTab";
import { useSelector, useDispatch } from "react-redux";
import GlobalFilterDrawer from "../../../components/GlobalFilterDrawer";
import { NotificationManager } from "react-notifications";
import EliteCardService from "../../../services/elite-cards-service";
import DashboardService from "../../../services/o2w-dashboard-service";
import { useNavigate } from "react-router-dom";
import { checkO2WActiveTabNo } from "../../../helper/commonData";
import {
  setTableSearchInput,
  setTableSortingData,
} from "../../../actions/tableListing/tableSortingActionData";
import { PayloadForApplyFilterData } from "../../../components/PayloadForApplyFilterData";

const { Search } = Input;
const { TabPane } = Tabs;

const Dashboard = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );
  const companyDetails = useSelector(
    (state) => state.CompanyDetails.companyDetials
  );

  const tableSortingData = useSelector(
    (state) => state.TableSortingData.tableListData
  );

  const tableSearchInput = useSelector(
    (state) => state.TableSortingData.tableSearchInput
  );

  const globalFilters = useSelector(
    (state) => state.GlobalFilters.globalFilterSapCodes
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const [isGlobalFilter, setIsGlobalFilter] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderSectionCount, setOrderSectionCount] = useState({});
  const [materialSectionCount, setMaterialSectionCount] = useState({});
  const [quantitySectionCount, setQuantitySectionCount] = useState({});
  const [quantitySectionMTCount, setQuantitySectionMTCount] = useState({});
  const [tabTableData, setTabTableData] = useState({});
  const [cardClasses, setCardClasses] = useState("");
  const [activeTabs, setActiveTabs] = useState("");
  const [isEmployee, setIsEmployee] = useState(false);
  const [tableSearchData, setTableSearchData] = useState(tableSearchInput);
  const [applyFilterObj, setApplyFilterObj] = useState(globalFilters || {});
  const [isLast7DaysData, setIsLast7DaysData] = useState(false);

  useEffect(() => {
    if (tableSortingData === null) {
      dispatch(
        setTableSortingData({
          currentPage: 1,
          orderBy: "desc",
          sortColumn: "",
        })
      );
    }

    if (userDetails?.isEmployee !== undefined) {
      setIsEmployee(userDetails.isEmployee);
      if (userDetails.isEmployee) {
        setCardClasses("col-xl-3 col-lg-6 col-12");
      } else {
        setCardClasses("col-xl-4 col-lg-6 col-12");
      }
    }

    const tabKey = checkO2WActiveTabNo(type);
    setActiveTabs(tabKey);

    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetDashboardCount();
      handleGetTabsDashboardData(tableSearchData);
    } else if (
      companyDetails ||
      Object.keys(applyFilterObj).length > 0 ||
      tableSortingData?.sortColumn
    ) {
      handleGetDashboardCount();
      handleGetTabsDashboardData(tableSearchData);
    }
  }, [
    tableSortingData,
    userDetails,
    applyFilterObj,
    companyDetails,
    type,
    isLast7DaysData,
  ]);

  // useEffect(() => {
  //   if (globalFilters && Object.keys(globalFilters)?.length > 0) {
  //     setApplyFilterObj(globalFilters);
  //   }
  // }, [globalFilters]);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      if (tableSearchData) {
        handleGetTabsDashboardData(tableSearchData);
      }
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [tableSearchData]);

  //// ---------------API Calling Function code start
  //// handle get tabs wise dashboard table listing
  const handleGetTabsDashboardData = async (tableSearchData) => {
    let sortColumnName =
      type === "material"
        ? "customer_PO_Date"
        : type === "invoice"
        ? "customer_PO_Date"
        : type === "vehicle"
        ? "container_Or_Vehicle_ID"
        : type === "assumption"
        ? "estimated_Plan_Date"
        : "customer_PO_Date";
    setIsLoading(true);
    let inputData = {
      pageindex: tableSortingData !== null ? tableSortingData?.currentPage : 1,
      pagesize: 10,
      sortcolumn:
        tableSortingData !== null
          ? tableSortingData?.sortColumn
          : sortColumnName,
      orderBy: tableSortingData !== null ? tableSortingData?.orderBy : "desc",
      tabName: type,
      search: tableSearchData === null ? "" : tableSearchData?.trim(),
      isLastSevenDays: isLast7DaysData,
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };
    await DashboardService.getTabsDashboardDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finalResponse = response.data.responseData;
          setIsLoading(false);
          if (finalResponse.totalRecords !== "0") {
            setTabTableData(finalResponse);
          } else {
            setIsLoading(false);
            setTabTableData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
        setIsLoading(false);
      });
  };
  //// handle get Dashboard counts
  const handleGetDashboardCount = () => {
    setIsCardsLoading(true);
    let inputData = {
      isLastSevenDays: isLast7DaysData,
      ...PayloadForApplyFilterData(applyFilterObj, companyDetails),
    };

    if (isGlobalFilter) {
      showFilterDrawer();
    }
    EliteCardService.getO2wTileDashboardCountsAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let objData = response.data.responseData;
          setOrderSectionCount(
            objData.order_section ? objData.order_section : "0"
          );
          setMaterialSectionCount(
            objData.material_section ? objData.material_section : "0"
          );
          setQuantitySectionCount(
            objData.quantity_section ? objData.quantity_section : "0"
          );
          setQuantitySectionMTCount(
            objData.quantity_in_MT ? objData.quantity_in_MT : "0"
          );
          setIsCardsLoading(false);
        } else {
          NotificationManager.error(response.data.message);
          setIsCardsLoading(false);
        }
      })
      .catch((error) => {
        setIsCardsLoading(false);
        console.log(error, "Error");
      });
  };
  //// ---------------API Calling Function code end

  /// apply filter code
  const handleApplyFilter = (objData) => {
    setApplyFilterObj(objData);
  };
  /// show filter drawer code
  const showFilterDrawer = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
    setIsGlobalFilter(!isGlobalFilter);
  };

  /// handle Table search input code
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
    let tempTableObjColumn = {
      currentPage: 1,
      orderBy: "desc",
      sortColumn: "",
    };
    handleGetTabsDashboardData(value, tempTableObjColumn);
  };

  /// handle Table search input onchange code
  const handleTblSearchChange = (e) => {
    const { value } = e.target;
    setTableSearchData(value);
    dispatch(setTableSearchInput(value));
  };

  //// handle tab change code
  const handleTabChange = (key) => {
    let tabName = "";
    setTableSearchData("");
    dispatch(setTableSearchInput());
    switch (key) {
      case "1":
        tabName = "all";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "2":
        tabName = "po";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "3":
        tabName = "material";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "4":
        tabName = "invoice";
        dispatch(
          setTableSortingData({
            sortColumn: "customer_PO_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "5":
        tabName = "vehicle";
        dispatch(
          setTableSortingData({
            sortColumn: "container_Or_Vehicle_ID", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;
      case "6":
        tabName = "assumption";
        dispatch(
          setTableSortingData({
            sortColumn: "estimated_Plan_Date", /// default sort column
            orderBy: "desc",
            currentPage: 1,
          })
        );
        break;

      default:
        tabName = "all";
        break;
    }

    navigate(`/dashboard/${tabName}`);
    setActiveTabs(key);
  };
  ////handle table sorting column
  const handleTableSortColumn = (data) => {
    dispatch(
      setTableSortingData({
        sortColumn: data.sortColumn ? data.sortColumn : "customer_PO_Date",
        orderBy: data.orderBy,
        currentPage: data.currentPage,
      })
    );
  };
  //// Filter data Exist code
  function hasFilterDataExist(obj) {
    return Object.values(obj).some((array) => array?.length > 0);
  }
  //// handle toggle changes code
  const handleToggleChange = (checked) => {
    setIsLast7DaysData(checked);
  };
  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            {hasFilterDataExist(applyFilterObj) && (
              <h5 className="appled_filter">Applied Filters</h5>
            )}
            <h5 className="content-header-title d-inline-block">
              Order to Warehouse (O2W)
            </h5>
            <div className="dis_blck_right">
              <label>Last 7 Days</label>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                className="toggle_days"
                checked={isLast7DaysData}
                onChange={handleToggleChange}
              />
            </div>
            <div className="main-setting">
              <Button
                className={`setting-button ${drawerOpen ? "open" : ""}${
                  userDetails?.designation === "Director" &&
                  companyDetails?.company_Code !== "1000"
                    ? "filter_btn_dis"
                    : ""
                }`}
                icon={<SettingOutlined spin />}
                onClick={() => showFilterDrawer()}
                disabled={
                  userDetails?.designation === "Director" &&
                  companyDetails?.company_Code !== "1000"
                }
              />
            </div>

            <Spin size="large" spinning={isCardsLoading}>
              <div className="row">
                <div className={cardClasses}>
                  <div className="card bg_info">
                    <div className="card-content">
                      <div className="card-body order images_bg">
                        <h4 className="text-white mb-5 font24">
                          <img
                            src={SmallOrder}
                            className="card_small_img"
                            alt="Img"
                          />
                          <span>
                            {Object.keys(orderSectionCount).length > 0 &&
                              orderSectionCount.total + " Orders"}
                          </span>
                        </h4>
                        <div className="d-flex justify-content-between text-center">
                          <div className="card_counts">
                            <h5>
                              {Object.keys(orderSectionCount).length > 0 &&
                                orderSectionCount.inProductionOrdersCount}
                            </h5>
                            <h6 className="text-white mb-1">Production</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5>
                              {Object.keys(orderSectionCount).length > 0 &&
                                orderSectionCount.inBothOrdersCount}
                            </h5>
                            <h6 className="text-white mb-1">In Both</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5>
                              {Object.keys(orderSectionCount).length > 0 &&
                                orderSectionCount.transitOrdersCount}
                            </h5>
                            <h6 className="text-white mb-1">Dispatch</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cardClasses}>
                  <div className="card bg_warning">
                    <div className="card-content">
                      <div className="card-body Materials images_bg">
                        <h4 className="text-white mb-5 font24">
                          <img
                            src={SmallMaterial}
                            className="card_small_img"
                            alt="Img"
                          />
                          <span>
                            {Object.keys(materialSectionCount).length > 0 &&
                              materialSectionCount.total + " Materials"}
                          </span>
                        </h4>
                        <div className="d-flex justify-content-between text-center">
                          <div className="card_counts">
                            <h5>
                              {Object.keys(materialSectionCount).length > 0 &&
                                materialSectionCount.inProductionMaterialCount}
                            </h5>
                            <h6 className="text-white mb-1">Production</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5>
                              {Object.keys(materialSectionCount).length > 0 &&
                                materialSectionCount.inBothMaterialCount}
                            </h5>
                            <h6 className="text-white mb-1">In Both</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5>
                              {Object.keys(materialSectionCount).length > 0 &&
                                materialSectionCount.transitMaterialCount}
                            </h5>
                            <h6 className="text-white mb-1">Dispatch</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cardClasses}>
                  <div className="card bg_danger">
                    <div className="card-content">
                      <div className="card-body Quantity images_bg">
                        <h4 className="text-white mb-5 font24 text_decor">
                          <img
                            src={SmallQuantity}
                            className="card_small_img"
                            alt="Img"
                          />
                          {Object.keys(quantitySectionCount).length > 0 &&
                            quantitySectionCount.total + " Quantity"}
                        </h4>
                        <div className="d-flex justify-content-between text-center">
                          <div className="card_counts">
                            <h5 className="text_decor">
                              {Object.keys(quantitySectionCount).length > 0 &&
                                quantitySectionCount.inProductionQuantity}
                            </h5>
                            <h6 className="text-white mb-1">Production</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5 className="text_decor">
                              {Object.keys(quantitySectionCount).length > 0 &&
                                quantitySectionCount.inBothQuantity}
                            </h5>
                            <h6 className="text-white mb-1">In Both</h6>
                          </div>
                          <div className="border-right-white border-right-lighten-3"></div>
                          <div className="card_counts">
                            <h5 className="text_decor">
                              {Object.keys(quantitySectionCount).length > 0 &&
                                quantitySectionCount.transitQuantity}
                            </h5>
                            <h6 className="text-white mb-1">Dispatch</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isEmployee && (
                  <div className={cardClasses}>
                    <div className="card  bg_success">
                      <div className="card-content">
                        <div className="card-body MTQuantity images_bg">
                          <h4 className="text-white mb-5 font24">
                            <img
                              src={SmallMTQ}
                              className="card_small_img"
                              alt="Img"
                            />
                            {Object.keys(quantitySectionMTCount).length > 0 &&
                              quantitySectionMTCount.total + " MT Quantity"}
                          </h4>
                          <div className="d-flex justify-content-between text-center">
                            <div className="card_counts">
                              <h5>
                                {Object.keys(quantitySectionMTCount).length >
                                  0 &&
                                  quantitySectionMTCount.inProductionQuantityMT}
                              </h5>
                              <h6 className="text-white mb-1">Production</h6>
                            </div>
                            <div className="border-right-white border-right-lighten-3"></div>
                            <div className="card_counts">
                              <h5>
                                {Object.keys(quantitySectionMTCount).length >
                                  0 && quantitySectionMTCount.inBothQuantityMT}
                              </h5>
                              <h6 className="text-white mb-1">In Both</h6>
                            </div>
                            <div className="border-right-white border-right-lighten-3"></div>
                            <div className="card_counts">
                              <h5>
                                {Object.keys(quantitySectionMTCount).length >
                                  0 && quantitySectionMTCount.transitQuantityMT}
                              </h5>
                              <h6 className="text-white mb-1">Dispatch</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Spin>
            <Card>
              <div className="row dashboard_tabs">
                <Tabs
                  activeKey={activeTabs}
                  className="dash_tab_panel"
                  type="card"
                  tabBarExtraContent={
                    <div className="tab_searchtxt">
                      <Search
                        placeholder="Search..."
                        className="dash_search"
                        allowClear
                        onSearch={handleOnSearchData}
                        size="large"
                        value={tableSearchData}
                        onChange={handleTblSearchChange}
                      />
                    </div>
                  }
                  onChange={handleTabChange}
                >
                  <TabPane tab="All" key="1">
                    {activeTabs === "1" && (
                      <AllTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="PO" key="2">
                    {activeTabs === "2" && (
                      <POTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="Material" key="3">
                    {activeTabs === "3" && (
                      <MaterialTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="Invoice" key="4">
                    {activeTabs === "4" && (
                      <InvoiceTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="Container/Truck" key="5">
                    {activeTabs === "5" && (
                      <ContainerTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                  <TabPane tab="Assumption ETA" key="6">
                    {activeTabs === "6" && (
                      <AssumptionEtaTab
                        tabTableData={tabTableData}
                        isLoading={isLoading}
                        OnhandleTableSortColumn={handleTableSortColumn}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="main-filter">
        <GlobalFilterDrawer
          isGlobalFilter={isGlobalFilter}
          showFilterDrawer={showFilterDrawer}
          isEmployee={isEmployee}
          applyFilter={handleApplyFilter}
        />
      </div>
    </Fragment>
  );
};

export default Dashboard;
