import React, { Fragment, useEffect, useState } from "react";
import { Card, Button, Select, DatePicker, Tabs, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import GatePassTable from "./Tables/gatePassTable";
import { imgDelete, exportFileImg } from "../Elite/imageAssets";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import DeleteConfirmationModal from "../../components/deleteConfirmationModal";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea, Search } = Input;

const CreateGatePass = () => {
  const userDetails = useSelector(
    (state) => state.loginReducer.loginDetials.userDetails
  );

  const [companyListData, setCompanyListData] = useState([]);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [transportationType, setTransportationType] = useState("Self Car");
  const [employeeNameData, setEmployeeNameData] = useState([]);
  const [gatePassTableData, setGatePassTableData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLunch, setSelectedLunch] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [vehicleInput, setVehicleInput] = useState("");
  const [remarksInput, setRemarksInput] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [lunchError, setLunchError] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);
  const [vehicleNoError, setVehicleNoError] = useState(false);
  const [isDeletMdlVisible, setIsDeletMdlVisible] = useState(false);
  const [tableSearchData, setTableSearchData] = useState("");
  const [isUpdateDeletId, setIsUpdateDeletId] = useState("");
  const [additionalDetailsData, setAdditionalDetailsData] = useState([
    { empId: null, lunch: null },
  ]);
  const [tableObjColumn, setTableObjColumn] = useState({
    sortColumn: "",
    orderBy: "",
    currentPage: "",
  });

  useEffect(() => {
    handleGetCompanyListData();
    handleGetEmployeeNameListData();
  }, []);

  useEffect(() => {
    handleGetGatePassTableListData();
  }, [tableObjColumn, tableSearchData]);

  useEffect(() => {
    setIsEmployee(userDetails.isEmployee);
  }, [userDetails]);

  ////// API calling functions start
  /// Get Employee name list API
  const handleGetEmployeeNameListData = () => {
    visitorManagementService
      .getEmployeeNameListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setEmployeeNameData(finaldata);
          } else {
            setEmployeeNameData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get company list API
  const handleGetCompanyListData = () => {
    visitorManagementService
      .getCompanyListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setCompanyListData(finaldata);
          } else {
            setCompanyListData({});
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// Get payment dashboard counts API
  const handleGetGatePassTableListData = () => {
    setIsTableLoading(true);
    let inputData = {
      pageindex:
        tableObjColumn.currentPage !== "" ? tableObjColumn?.currentPage : 1,
      pagesize: 10,
      sortcolumn: tableObjColumn !== "" ? tableObjColumn?.sortColumn : "",
      orderBy: tableObjColumn.orderBy !== "" ? tableObjColumn?.orderBy : "desc",
      search: tableSearchData,
    };
    visitorManagementService
      .getCreateGatePassTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (Object.keys(finaldata).length > 0) {
            setGatePassTableData(finaldata);
          } else {
            setGatePassTableData({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTableLoading(false);
      })
      .catch((error) => {
        setIsTableLoading(false);
        console.log(error, "Error");
      });
  };
  //// handle Save form data
  const handleSaveFormData = () => {
    if (handleFormValidation()) {
      let inputData = {
        ...(isUpdateDeletId && { id: isUpdateDeletId }),
        companyId: selectedCompany,
        numberOfPeople:
          additionalDetailsData.length > 1
            ? (additionalDetailsData.length + 1).toString()
            : additionalDetailsData.length === 1
            ? additionalDetailsData[0].empId !== null ||
              additionalDetailsData[0].lunch !== null
              ? (additionalDetailsData.length + 1).toString()
              : "1"
            : "1",
        date: selectedDateTime,
        lunch: selectedLunch,
        transport: transportationType,
        carNumber: vehicleInput,
        remarks: remarksInput,
        additionalGatePass:
          additionalDetailsData.length > 1
            ? additionalDetailsData
            : additionalDetailsData.length === 1 &&
              (additionalDetailsData[0].empId !== null ||
                additionalDetailsData[0].lunch !== null)
            ? additionalDetailsData
            : [],
      };
      visitorManagementService
        .postCreateGatePassAPI(inputData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            let finaldata = response.data.responseData;
            if (finaldata !== "") {
              NotificationManager.success(finaldata);
              handleGetGatePassTableListData();
              handleResetFormData();
            } else {
              NotificationManager.error(response.data.message);
            }
            setIsUpdateDeletId("");
          }
        })
        .catch((error) => {
          console.log(error, "Error");
        });
    }
  };
  /// handle delete API
  const handleDeleteAPICalled = () => {
    visitorManagementService
      .deleteGatePassAPI(isUpdateDeletId)
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata === "Deleted Successfully") {
            NotificationManager.success("Record Deleted Successfully!");
            handleGetGatePassTableListData();
            setIsDeletMdlVisible(false);
            setIsUpdateDeletId("");
          } else {
            NotificationManager.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  //Export the data Api
  const handleExportGatePassData = () => {
    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: "",
      orderBy: "",
      search: "",
      isReport: true,
    };

    visitorManagementService
      .postReceptionReportExportPDF(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Gate_Pass.xlsx`);

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel Export Successful!");
      })
      .catch((error) => {
        NotificationManager.error("Excel export failed.");
      });
  };
  ////// API calling functions end

  /// handle Table search input code
  const handleOnSearchData = (value) => {
    setTableSearchData(value);
    handleGetGatePassTableListData();
  };

  /// handle Table search input onchange code
  const handleTblSearchChange = (e) => {
    const { value } = e.target;
    setTableSearchData(value);
  };
  //// handle reset form data
  const handleResetFormData = () => {
    setSelectedCompany(null);
    setSelectedLunch(null);
    setSelectedDateTime(null);
    setVehicleInput("");
    setRemarksInput("");
    setAdditionalDetailsData([{ empId: null, lunch: null }]);
    setCompanyError(false);
    setLunchError(false);
    setDateTimeError(false);
    setVehicleNoError(false);
  };
  //// handle Form validation code
  const handleFormValidation = () => {
    let isValidation = true;
    if (selectedCompany === null) {
      setCompanyError(true);
      isValidation = false;
    }
    if (selectedLunch === null) {
      setLunchError(true);
      isValidation = false;
    }
    if (selectedDateTime === null) {
      setDateTimeError(true);
      isValidation = false;
    }
    if (selectedDateTime === null) {
      setDateTimeError(true);
      isValidation = false;
    }
    if (transportationType === "Self Car") {
      if (vehicleInput === "") {
        setVehicleNoError(true);
        isValidation = false;
      }
    }

    if (additionalDetailsData.length > 1) {
      for (let i = 0; i < additionalDetailsData.length; i++) {
        const { empId, lunch } = additionalDetailsData[i];
        if (empId === null) {
          NotificationManager.error("Please select Name!");
          isValidation = false;
          break;
        }
        if (lunch === null) {
          NotificationManager.error("Please select Lunch!");
          isValidation = false;
          break;
        }
      }
    }
    return isValidation;
  };
  //// handle select TransportType onchange
  const handleSelectTransportationType = (e) => {
    if (e === "Company Car") {
      setVehicleNoError(false);
    }
    setTransportationType(e);
  };

  //// handle additional details Onchange
  const handleAdditionalDetailsOnChange = (e, i, name) => {
    let additionalDetails = [...additionalDetailsData];
    additionalDetails[i] = { ...additionalDetails[i], [name]: e };
    setAdditionalDetailsData(additionalDetails);
  };

  //// handle add new Details
  const handleAddnewDetails = () => {
    setAdditionalDetailsData((prevState) => [
      ...prevState,
      { empId: null, lunch: null },
    ]);
  };

  //// handle remove the details
  const handleElementsRemove = (i) => {
    let addDetails = [...additionalDetailsData];
    addDetails.splice(i, 1);
    setAdditionalDetailsData(addDetails);
  };

  //// table sorting column
  const handleTableSortColumn = (data) => {
    setTableObjColumn({
      sortColumn: data.sortColumn,
      orderBy: data.orderBy,
      currentPage: data.currentPage,
    });
  };
  /// handle delete modal open
  const handleDeleteData = (id) => {
    setIsDeletMdlVisible(true);
    setIsUpdateDeletId(id);
  };
  //// handle fetch edit data
  const handleEditData = (editData) => {
    const tempDateTime = moment(
      `${editData.date} ${editData.time}`,
      "DD/MM/YYYY HH:mm:ss"
    );
    setIsUpdateDeletId(editData.id);
    setSelectedCompany(editData.companyId);
    setSelectedLunch(editData.lunch);
    setSelectedDateTime(tempDateTime);
    setVehicleInput(editData.carNumber);
    setRemarksInput(editData.remarks);
    setTransportationType(editData.transport);
    setAdditionalDetailsData(
      editData.additionalGatePassdetails.length === 0
        ? [{ empId: null, lunch: null }]
        : editData.additionalGatePassdetails
    );
  };
  //// handle drop down onchage
  const handleDropdownOnChange = (e, check) => {
    if (check === "company") {
      setSelectedCompany(e);
    } else {
      setSelectedLunch(e);
    }
  };
  /// handle Date picker onchange
  const handleDateChange = (value, dateString) => {
    if (value && moment.isMoment(value)) {
      setSelectedDateTime(value);
    } else {
      setSelectedDateTime(null);
    }
  };
  /// handle close delete modal
  const handleCloseDeleteMdl = () => {
    setIsDeletMdlVisible(false);
    setIsUpdateDeletId("");
  };

  return (
    <Fragment>
      <div className="app-content content visitorPass">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Create Gate Pass
            </h5>

            <div className="row">
              <div className="col-xl-4 col-lg-4 col-12 ">
                <div className="card ">
                  <div className="card-content">
                    <div
                      className="card-body "
                      style={{ padding: "15px 20px" }}
                    >
                      <h4 className="ft-16 fnt_w_bold">Person Details</h4>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-12 px-1 ">
                          <Select
                            allowClear
                            placeholder="Select Company"
                            onChange={(e) =>
                              handleDropdownOnChange(e, "company")
                            }
                            value={selectedCompany}
                            optionFilterProp="children"
                          >
                            {companyListData?.map((item) => {
                              return (
                                <Option value={item.id} key={item.id}>
                                  {item.company}
                                </Option>
                              );
                            })}
                          </Select>
                          {companyError && (
                            <p className="inputError">
                              Company Name is Required!
                            </p>
                          )}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-12 px-1 col-12-pt-15 ">
                          <DatePicker
                            showTime
                            showNow={false}
                            showSecond={false}
                            placeholder="Select Date/Time"
                            value={selectedDateTime}
                            onChange={handleDateChange}
                          />
                          {dateTimeError && (
                            <p className="inputError">Date/Time is Required!</p>
                          )}
                        </div>
                      </div>
                      <div className="row pt-15">
                        <div className="col-xl-6 col-lg-6 col-12 px-1 ">
                          <Select
                            placeholder="Lunch"
                            onChange={(e) => handleDropdownOnChange(e, "lunch")}
                            value={selectedLunch}
                            optionFilterProp="children"
                          >
                            <Option value="Yes">Yes</Option>
                            <Option value="No">No</Option>
                          </Select>
                          {lunchError && (
                            <p className="inputError">Lunch is Required!</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12 ">
                <div className="card transport_type">
                  <div className="card-content">
                    <div className="card-body ">
                      <h4 className="ft-16 fnt_w_bold">
                        Transportation Details
                      </h4>
                      <Tabs
                        activeKey={transportationType}
                        className="tab_panel"
                        type="card"
                        onChange={handleSelectTransportationType}
                      >
                        <TabPane tab="Self Car" key="Self Car" />
                        <TabPane tab="Company Car" key="Company Car" />
                      </Tabs>
                      <Input
                        placeholder="Vehicle Number"
                        disabled={transportationType === "Company Car"}
                        value={vehicleInput}
                        onChange={(e) => setVehicleInput(e.target.value)}
                      />
                      {vehicleNoError && (
                        <p className="inputError">Vehicle No is Required!</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-12">
                <div className="card ">
                  <div className="card-content">
                    <div className="card-body  ">
                      <h4 className="ft-16 fnt_w_bold">Remarks</h4>
                      <TextArea
                        value={remarksInput}
                        placeholder="User Inputs"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        onChange={(e) => setRemarksInput(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Card>
              <h4 className="ft-16 fnt_w_bold">Additional Details</h4>
              <div className="additional-details-container">
                {additionalDetailsData.map((item, i) => {
                  return (
                    <div className="additional-details-row" key={i}>
                      <div className="mr_10">
                        <Select
                          allowClear
                          showSearch
                          placeholder="Name"
                          style={{
                            width: window.innerWidth < 768 ? 100 : 170,
                          }}
                          value={item.empId || null}
                          onChange={(e) =>
                            handleAdditionalDetailsOnChange(e, i, "empId")
                          }
                        >
                          {employeeNameData.map((data) => {
                            return (
                              <Option value={data.id} key={data.id}>
                                {data.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="mr_10">
                        <Select
                          placeholder="Lunch"
                          style={{
                            width: window.innerWidth < 768 ? 100 : 170,
                          }}
                          value={item.lunch || null}
                          onChange={(e) =>
                            handleAdditionalDetailsOnChange(e, i, "lunch")
                          }
                        >
                          <Option value="Yes">Yes</Option>
                          <Option value="No">No</Option>
                        </Select>
                      </div>
                      {i === 0 ? (
                        <div className="mr_12  visitor_add_details">
                          <Button
                            className={`addButton ${
                              additionalDetailsData.length === 4
                                ? "disabled_add_new_btn"
                                : ""
                            }`}
                            shape="circle"
                            icon={<PlusOutlined />}
                            disabled={additionalDetailsData.length === 4}
                            onClick={handleAddnewDetails}
                          />
                        </div>
                      ) : (
                        <img
                          src={imgDelete}
                          alt="img"
                          className="remove_Details"
                          onClick={() => handleElementsRemove(i)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Card>
            <div className="reset_save_btn">
              <Button className="reset_btn" onClick={handleResetFormData}>
                Reset
              </Button>
              <Button className="save_btn" onClick={handleSaveFormData}>
                Save
              </Button>
            </div>
            <div className="vertical_line" />
            <div className="gate_pass_section row ">
              <div className="row payment_tab col-12 col-xl-6 ">
                <label className="ft-16 fnt_w_bold ">Created Gate Pass</label>
              </div>
              <div className="d-flex col-12 col-xl-6 justify-content-end">
                <div className="tab_searchtxt pb_0">
                  <Search
                    placeholder="Search..."
                    className="dash_search"
                    allowClear
                    onSearch={() => handleOnSearchData()}
                    size="large"
                    value={tableSearchData}
                    onChange={handleTblSearchChange}
                  />
                </div>
                <div className="export_lbl">
                  <label onClick={handleExportGatePassData}>
                    Export
                    <img src={exportFileImg} alt="img" />
                  </label>
                </div>
              </div>
            </div>
            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <GatePassTable
                    gatePassTableData={gatePassTableData}
                    gatePassLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    handleDeleteData={handleDeleteData}
                    handleEditData={handleEditData}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        visible={isDeletMdlVisible}
        onDelete={handleDeleteAPICalled}
        onCancel={handleCloseDeleteMdl}
      />
    </Fragment>
  );
};

export default CreateGatePass;
