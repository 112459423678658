import {
  SET_GLOBALFILTER_IDS,
  SET_GLOBALFILTER_SAPCODE,
  SET_GLOBALRESETFILTER,
} from "../../actions/globalFilter/globalFilterTypes";

const initialState = {
  globalFiltersIds: null,
  globalFilterSapCodes: null,
};

function globalFilterReducer(filterStates = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_GLOBALFILTER_IDS:
      return { ...filterStates, globalFiltersIds: payload };
    case SET_GLOBALFILTER_SAPCODE:
      return { ...filterStates, globalFilterSapCodes: payload };
    case SET_GLOBALRESETFILTER:
      return {
        ...filterStates,
        globalFiltersIds: undefined,
        globalFilterSapCodes: undefined,
      };
    default:
      return filterStates;
  }
}

export default globalFilterReducer;
