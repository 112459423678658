import React, { Fragment, useState, useEffect } from "react";
import { imgCubes } from "./imageAssets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const EliteCard = () => {
  const navigate = useNavigate();
  const [userApplications, setUserApplications] = useState([]);
  const userApps = useSelector(
    (state) => state.loginReducer.loginDetials.userApplications
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (userApps?.length > 0) {
      userApps?.forEach((el) => {
        el.isHover = false;
      });
      setUserApplications(userApps);
    }
  }, [userApps]);

  //// handle card onclick
  const handleCardClick = (Id) => {
    if (Id === 2) {
      navigate("/eliteDashboard", { state: Id });
    } else if (Id === 9) {
      navigate("/visitorHome");
    }
  };

  //// handle card hover base on the id
  const handleHoverCard = (id) => {
    setUserApplications((prevApplications) =>
      prevApplications.map((item) =>
        item.aP_Id === id
          ? { ...item, isHover: true }
          : { ...item, isHover: false }
      )
    );
  };

  return (
    <Fragment>
      <div className="dashboard app-content content">
        <div className="content-wrapper">
          <div className="content-body">
            <div className="row">
              {userApplications.length > 0 &&
                userApplications.map((item) => {
                  return (
                    <div className="col-xl-3 col-lg-6 col-12" key={item.aP_Id}>
                      <div
                        className={`card card-hover cur_point ${
                          item.isHover ? "hovered" : ""
                        }`}
                        onClick={() => handleCardClick(item.aP_Id)}
                        onMouseEnter={() => handleHoverCard(item.aP_Id)}
                        onMouseLeave={() => handleHoverCard(item.aP_Id)}
                      >
                        <div className="card-content">
                          <div className="card-body d-flex justify-content-between">
                            <img src={imgCubes} alt="img" />
                            <h4 className="text-info pt-15">{item.aP_Name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div className="col-xl-3 col-lg-6 col-12">
                <div
                  className={`card card-hover cur_point ${
                    isEliteHovered ? "hovered" : ""
                  }`}
                  onClick={() => handleCardClick("elite")}
                  onMouseEnter={() => setIsEliteHovered(true)}
                  onMouseLeave={() => setIsEliteHovered(false)}
                >
                  <div className="card-content">
                    <div className="card-body d-flex justify-content-between">
                      <img src={imgCubes} alt="img" />
                      <h4 className="text-info pt-15">Elite</h4>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EliteCard;
