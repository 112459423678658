import React, { Fragment, useState, useEffect } from "react";
import { Select, Button, Card, DatePicker } from "antd";
import { pdfImg } from "../Elite/imageAssets";
import ReceptionReportTable from "./Tables/ReceptionReportTable";
import visitorManagementService from "../../services/VisitorManagement/visitorManagement-service";
import { NotificationManager } from "react-notifications";
import moment from "moment";

const { Option } = Select;

const ReceptionReport = () => {
  const [assignVehiclesListData, setAssignVehiclesListData] = useState([]);
  const [AssignVehicles, setAssignVehicles] = useState(null);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [receptionReportTableData, setReceptionReportTableData] = useState({});

  useEffect(() => {
    fetchReportData();
    handleGetAssignVehiclesListData();
  }, []);

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  //// table sorting column
  const handleTableSortColumn = (pagination, filters, sorter) => {
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    const sortField = sorter.field || "";

    // Pass the selected date or default to today
    fetchReportData(
      selectedDate || moment().format("YYYY-MM-DD"),
      sortField,
      sortOrder,
      filters,
      pagination
    );
  };

  //// API calling function start
  /// Get company list API
  const handleGetAssignVehiclesListData = () => {
    visitorManagementService
      .getAssignVehiclesListAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          let finaldata = response.data.responseData;
          if (finaldata.length > 0) {
            setAssignVehiclesListData(finaldata);
          } else {
            setAssignVehiclesListData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error, "Error");
      });
  };
  /// fetch the resport data
  const fetchReportData = (
    date = moment().format("YYYY-MM-DD"),
    sortField = "",
    sortOrder = ""
  ) => {
    setIsTableLoading(true);

    const inputData = {
      pageindex: 1,
      pagesize: 10,
      sortcolumn: sortField,
      orderBy: sortOrder,
      search: date,
      isReport: true,
    };

    visitorManagementService
      .getCreateGatePassTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setReceptionReportTableData(response.data.responseData || {});
        } else {
          NotificationManager.error(
            response.data.message || "Error fetching data"
          );
        }
      })
      .catch(() => NotificationManager.error("Error fetching data"))
      .finally(() => setIsTableLoading(false));
  };
  //Export the data Api
  const handleExportReceptionReportData = () => {
    const currentDate = selectedDate || moment().format("YYYY-MM-DD");
    const inputData = {
      pageindex: 0,
      pagesize: 0,
      sortcolumn: "",
      orderBy: "",
      search: currentDate,
      isReport: true,
    };

    visitorManagementService
      .postReceptionReportExportPDF(inputData)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Reception_Report_${currentDate}.xlsx`
        );

        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);

        NotificationManager.success("Excel Export Successful!");
      })
      .catch((error) => {
        NotificationManager.error("Excel export failed.");
      });
  };
  //// handle submit data api
  const handleSubmit = () => {
    if (!selectedDate) {
      NotificationManager.error("Please select a date to filter!");
      return;
    }

    setIsTableLoading(true);
    const inputData = {
      pageindex: 1,
      pagesize: 10,
      sortcolumn: "",
      orderBy: "",
      search: selectedDate,

      isReport: true,
    };

    visitorManagementService
      .getCreateGatePassTableDataAPI(inputData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const finaldata = response.data.responseData;
          if (Object.keys(finaldata).length > 0) {
            setReceptionReportTableData(finaldata);
          } else {
            setReceptionReportTableData({});
          }
        } else {
          NotificationManager.error(response.data.message);
        }
        setIsTableLoading(false);
      })
      .catch((error) => {
        setIsTableLoading(false);
        console.error("Error fetching table data:", error);
      });
  };

  //// API calling function end

  //// handle fetch edit data
  const handleEditData = (editData) => {
    setAssignVehicles(editData.vehicleId);
  };
  //// handle drop down onchange code
  const handleDropdownOnChange = (value, check) => {
    if (check === "vehicle") {
      setAssignVehicles(value);
    }
  };

  //// reset filter values
  const handleResetFormData = () => {
    setSelectedDate(null);
    setAssignVehicles("");
    fetchReportData();
  };
  return (
    <Fragment>
      <div className="app-content content visitorPass receptionReport">
        <div className="content-wrapper">
          <div className="content-body">
            <h5 className="content-header-title d-inline-block">
              Reception Report
            </h5>

            <div className="gate_pass_section">
              <div className="visitor_reports row">
                <div className=" report_section col-xl-9 col-12">
                  <label>Date of visit</label>
                  <div>
                    <DatePicker
                      className="resport_date"
                      onChange={handleDateChange}
                      placeholder="Select Date"
                      showToday={false}
                      value={selectedDate ? moment(selectedDate) : null}
                    />
                  </div>

                  <div>
                    <Select
                      showSearch
                      className="resport_select"
                      placeholder="Assign Vehicles"
                      optionFilterProp="children"
                      onChange={(value) =>
                        handleDropdownOnChange(value, "vehicle")
                      }
                      value={AssignVehicles || null}
                    >
                      {assignVehiclesListData?.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.vehicleName}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="reset_save_btn pt-0">
                    <Button className="save_btn" onClick={handleSubmit}>
                      Submit
                    </Button>
                    <Button
                      className="reset_btn ml-20"
                      onClick={handleResetFormData}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
                <div className="report_pdf col-xl-3 col-12 justify-content-end d-flex">
                  <label onClick={handleExportReceptionReportData}>
                    Export PDF
                    <img src={pdfImg} alt="img" />
                  </label>
                </div>
              </div>
            </div>

            <Card className="pay_section_2">
              <div className="row">
                <div className="col-md-12">
                  <ReceptionReportTable
                    receptionReportData={receptionReportTableData}
                    gatePassLoading={isTableLoading}
                    OnhandleTableSortColumn={handleTableSortColumn}
                    handleEditData={handleEditData}
                    AssignVehicles={AssignVehicles}
                    setAssignVehicles={setAssignVehicles}
                    fetchReportData={fetchReportData}
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReceptionReport;
